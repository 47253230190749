<template>
  <div class="w-full max-w-xs mx-auto">
    <form @submit.prevent="login()">
      <div class="input-wrapper">
        <label for="username">Identifiant ou adresse e-mail</label><br />
        <input
          v-model="form.username"
          id="username"
          type="text"
          placeholder="Username"
          autocomplete="username"
        />
      </div>
      <div class="input-wrapper">
        <label for="password">Mot de passe</label><br />
        <input
          v-model="form.password"
          id="password"
          type="password"
          placeholder="•••••••••••"
          autocomplete="current-password"
        />
      </div>
      <div class="error-login" v-if="errorLogin">Mauvais identifiant ou mot de passe !</div>
      <div class="center">
        <button class="button" type="submit">se connecter</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      errorLogin: false
    };
  },
  methods: {
    async login() {
      this.error = false;
      try {
        const result = await this.$store.dispatch("login", this.form);
        const { redirect = false } = this.$route.query;
        const path = redirect ? decodeURI(redirect) : "/";
        this.$router.push({ path });
      } catch (e) {
        console.log("Error login");
        console.log(e);
        this.errorLogin = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../sass/base/variables";

form {
  width: max-content;
  margin: 0 auto;
  padding: 15px;
  box-shadow: 0 5px 15px rgba($purple, 0.2);
}

.error-login {
  color: red;
  text-align: center;
}

.input-wrapper {
  width: 100%;
  margin-bottom: 5px;

  input {
    margin-top: 3px;
    width: 280px;
    height: 30px;
  }
}
.button {
  margin-top: 15px;
}
.center {
  text-align: center;
}
</style>