<template>
  <div class="login">
    <div class="container mx-auto px-4">
      <div class="flex -mx-4">
        <div class="w-full">
          <h1 class="">Connexion</h1>
        </div>
      </div>
      <div class="flex -mx-4">
        <div class="w-full">
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm'
export default {
  components: {
    LoginForm
  } 
}
</script>

<style scoped lang="scss">
h1 {
  text-align: center;
  margin-top: 50px;
}
</style>
