<template>
  <div class="load">
    <div class="load__inner">
      <h2>Plans de communication enregistrés</h2>
      <ul>
        <li v-for="plan in plans" :key="plan.ID" class="plan">
          <span class="plan-title">{{ plan.post_title }}</span>
          <div class="buttons">
            <button @click="load(plan.ID)" title="Modifier">
              <i class="fas fa-pen-alt"></i>
            </button>
            <!-- <button @click="generatePDF(plan.ID)" title="Générer PDF">
              <i class="fas fa-file-pdf"></i>
            </button> -->
          </div>
          <div class="plan__author">({{getAuthor(plan)}})</div>
        </li>
      </ul>
      <router-link to="/" class="button">retour</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  computed: {
    plans() {
      return this.$store.getters.plans;
    },
  },
  methods: {
    load(id) {
      this.$store.dispatch("setCurrentPlan", {planId: id, redirect: true});
    },
    generatePDF(id) {
      this.$store.dispatch("setCurrentPlan", {planId: id, redirect: false});
      this.$store.dispatch("startGeneratePDF");
    },
    getAuthor(plan) {
      const content = JSON.parse(plan.post_content);
      return content.info.author;
    }
  },

  mounted() {
    this.$store.dispatch("fetchPlans");
  },
};
</script>

<style scoped lang="scss">
@import "../sass/base/variables";

h2 {
  margin-bottom: 30px;
}
.load {
  background-color: rgba($purple, 0.2);
  padding: 50px;
  min-height: 100vh;

  &__inner {
    background-color: $white;
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 5px;
  }
}

.plan {
  padding: 5px;
  border-bottom: 1px solid rgba($black, 0.1);
  position: relative;
  transition: background-color .15s ease;

  &:hover {
    background-color: rgba($black,.1);
  }

  &:last-child {
    border-bottom: none;
  }

  &__author {
    font-size: 10px;
    color: grey;
  }
}
.buttons {
  float: right;

  button {
    display: inline-block;
    margin-left: 3px;
    padding: 3px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    transition: all 0.15s ease;
    &:hover {
      color: $white;
      background-color: $purple;
    }
  }
}
h2 {
  text-align: center;
}
button {
  color: $purple;
  &:hover {
    text-decoration: underline;
  }
}
.button {
  display: block;
  width: max-content;
  text-align: center;
  margin: 50px auto 0 auto;
}
</style>